import http from '@/utils/http'

// 新增或编辑检验单配置
export function addOrUpdateQumaAllo (p) {
  return http.post('/material/qualityManage/addOrUpdateQumaAllo', p)
}

// 获取检验单模板列表
export function getQumaAllo (p) {
  return http.get('/material/qualityManage/getQumaAllo', p)
}

// 获取检验单模板列表-不分页
export function getQumaAlloByType (p) {
  return http.get('/material/qualityManage/getQumaAlloByType', p)
}

// 获取检验单模板详情
export function qumaAlloDetail (p) {
  return http.get('/material/qualityManage/qumaAlloDetail', p)
}

// 删除检验单模板
export function deleteQumaAllo (p) {
  return http.post('/material/qualityManage/deleteQumaAllo', p)
}

// 导出检验单配置
export function exportQumaAllo (p) {
  return http.postBlob('/material/qualityManage/exportQumaAllo', p)
}

// 导出检验单配置excel模板
export function exportQumaAlloDemo (p) {
  return http.postBlob('/material/qualityManage/exportQumaAlloDemo', p)
}

// 导入检验单配置
export function importQumaAllo (p) {
  return http.postForm('/material/qualityManage/importQumaAllo', p)
}
