<template>
  <!-- 混凝土入厂检测 -->
  <PageContainer>
    <BaseSearchForm
      @search="onSearch"
      @reset="onReset"
      :loading="loading">
      <el-form-item label="企业名">
        <el-input v-model.trim="searchForm.compName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="质检模板名称">
        <el-input v-model.trim="searchForm.alloName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="样品名称">
        <el-input v-model.trim="searchForm.sampName" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="质检结果">
        <el-select v-model="searchForm.syntEvalu" clearable>
          <el-option value="合格">合格</el-option>
          <el-option value="不合格">不合格</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格型号">
        <el-input v-model.trim="searchForm.model" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item label="年份">
        <el-date-picker
          v-model="searchForm.annual"
          type="year"
          value-format="yyyy"
          placeholder="选择年份"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="所属区域">
        <el-cascader
          ref="myCascader"
          v-model="selectedOptions"
          clearable
          placeholder="请选择区域"
          :options="regionOptions"
          :props="regionProps"
          filterable
        ></el-cascader>
      </el-form-item>
    </BaseSearchForm>

    <!-- <div>
        <el-button type="primary" icon="el-icon-plus" @click="editVisible=true">新增</el-button>
        <el-upload
          style="display:inline-block"
          action="#"
          class="upload ml-10"
          :auto-upload="false"
          accept=".xlsx,.xls"
          :on-change="handleExcel"
          :show-file-list="false">
          <el-button
            type="primary"
            icon="el-icon-upload2"
          >导入
          </el-button>
        </el-upload>
        <el-button class="ml-10" type="primary" icon="el-icon-download" @click="exportTmpl">导出模板</el-button>
      </div> -->

    <BaseTable
      id="enterQualityTest"
      @page-change="handlePageChange"
      :tablePage="tablePage"
      :loading="loading"
      :data="tableData">
      <vxe-column type="seq" field="seq" title="序号" width="80" align="center"></vxe-column>
      <vxe-column field="annual" title="所属年份"></vxe-column>
      <vxe-column field="reptNo" title="报告编号"></vxe-column>
      <vxe-column field="reptTime" title="报告时间"></vxe-column>
      <vxe-column field="syntEvalu" title="结果"></vxe-column>
      <vxe-column field="typeName" title="类型"></vxe-column>
      <vxe-column field="alloName" title="质检单模板名称"></vxe-column>
      <vxe-column field="prodName" title="生产企业名称"></vxe-column>
      <vxe-column field="projName" title="工程名称"></vxe-column>
      <vxe-column field="constructionFirm" title="施工单位"></vxe-column>
      <vxe-column field="position" title="部位"></vxe-column>
      <vxe-column field="mixProportion" title="配合比编号"></vxe-column>
      <vxe-column field="model" title="规格型号"></vxe-column>
      <vxe-column field="sampName" title="样品名称"></vxe-column>
      <!-- <vxe-column title="操作" width="220">
        <template #default="{row}">
          <el-button type="primary" @click="handlerEdit(row)">编辑</el-button>
          <el-button type="primary" @click="handlerDetail(row)">详情</el-button>
          <el-button type="primary" @click="handlerExport(row)">导出</el-button>
          <el-button type="danger" @click="handlerDelete(row)">删除</el-button>
        </template>
      </vxe-column> -->
    </BaseTable>

    <!-- 新增|编辑 -->
    <EditQualityTest :visible.sync="editVisible" :edit-data.sync="editData" @refresh="initList"/>

    <!-- 详情 -->
    <QualityTestDetail :visible.sync="detailVisible" :edit-data.sync="editData"/>
  </PageContainer>
</template>

<script>
import { gainRegi } from '@/apis/common'
import {
  getInspectionReptList,
  deleteDataInspectionRept,
  exportDataInspectionRept,
  exportDataInspectionReptDemo,
  importDataInspectionRept
} from '@/apis/quality/leaveQualityTest'
import { exportToxlsx } from '@/utils/tools'
import EditQualityTest from './components/EditQualityTest'
import QualityTestDetail from './components/QualityTestDetail'
export default {
  data () {
    return {
      searchForm: {
        alloName: '', // 检验单配置名称
        annual: '', // 检验年度 格式 YYYY
        compName: '', // 企业名
        model: '', // 规格型号
        sampName: '', // 样品名称
        syntEvalu: '', // 质检结果
        type: 2, // 检验报告类型 1 混凝土出厂检验报告 2 混凝土进厂检验报告 3 混凝土实体质量检验报告
        regiProvinId: '', // 省id
        regiCityId: '', // 市id
        regiCountrId: '' // 区id
      },
      // 列表相关
      loading: false,
      tableData: [],
      tablePage: {
        pageNum: 1,
        pageSize: 20,
        totalSize: 0
      },
      // 编辑相关
      editData: null,
      editVisible: false,
      detailVisible: false,
      bindVisible: false,
      regionOptions: [],
      regionProps: {
        //  multiple: true,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          const level = node.level
          if (level === 1) {
            resolve(node.children[0].label == '直辖区' ? [] : node.children)
            // resolve(node.children)
          }
          if (level === 2 && node.data.code) {
            if (node.children && node.children.lenght > 0) {
              resolve(node.children)
            } else {
              setTimeout(() => {
                gainRegi({
                  isMapJson: false,
                  regiLevel: node.level,
                  regiCodes: [node.data.code],
                  regiDto: {
                  }
                }).then(res => {
                  if (res.code == 200) {
                    const nodeArr = res.data[0].regiCountrs.map(val => ({
                      value: val.regiCountrId,
                      label: val.countrName,
                      code: val.countrCode,
                      leaf: true
                    })
                    )
                    resolve(nodeArr)
                  }
                })
              })
            }
          } else if (level == 3) {
            resolve([])
          }
        }
      }
    }
  },
  computed: {
    selectedOptions: {
      get () {
        const searchForm = this.searchForm
        const regiProvinId = searchForm.regiProvinId || undefined
        const regiCityId = searchForm.regiCityId || undefined
        const regiCountrId = searchForm.regiCountrId || undefined
        return [regiProvinId, regiCityId, regiCountrId]
      },
      set (areaCodes) {
        const [regiProvinId, regiCityId, regiCountrId] = areaCodes
        const searchForm = this.searchForm
        searchForm.regiProvinId = regiProvinId
        searchForm.regiCityId = regiCityId
        searchForm.regiCountrId = regiCountrId
      }
    }
  },
  created () {
    this.initList()
    gainRegi({
      regiLevel: 1,
      isMapJson: false,
      regiDto: {}
    }).then(res => {
      if (res.code == 200) {
        this.regionOptions = res.data.map(val => ({
          value: val.regiProvinId,
          label: val.provinName,
          code: val.provinCode,
          children: val.regiCitys.map(valCity => ({
            value: valCity.regiCityId,
            label: valCity.cityName,
            code: valCity.cityCode,
            children: []
          }))
        }))
      }
    }).catch(val => {
      console.log(val)
    })
  },
  methods: {
    onReset () {
      const source = this.$options.data(this).searchForm
      this.searchForm = Object.assign({}, this.searchForm, source)
      this.onSearch()
    },

    onSearch () {
      this.tablePage.pageNum = 1
      this.initList()
    },

    initList () {
      this.loading = true
      const { tablePage } = this
      const { totalSize, ...pageParams } = this.tablePage
      const params = Object.assign({}, this.searchForm, pageParams)
      getInspectionReptList(params).then((res) => {
        this.loading = false
        if (res.code == 200) {
          this.tableData = res.data.rows.map((item) => {
            if (item.type == 1) {
              item.typeName = '混凝土出厂检验报告'
            } else if (item.type == 2) {
              item.typeName = '混凝土进厂检验报告'
            } else if (item.type == 3) {
              item.typeName = '混凝土实体质量检验报告'
            }
            return item
          })
          tablePage.totalSize = res.data.totalSize
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    handlerEdit (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },

    handlerDetail (row) {
      this.editData = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },

    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.pageNum = currentPage
      this.tablePage.pageSize = pageSize
      this.initList()
    },

    async handlerExport ({ inspectionReptId }) {
      const res = await exportDataInspectionRept({ inspectionReptId })
      exportToxlsx(res, '检验报告单')
    },

    handlerDelete ({ inspectionReptId }) {
      this.$confirm('此操作将永久删除该报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteDataInspectionRept({ inspectionReptId }).then((res) => {
          if (res.code == 200) {
            this.initList()
            this.$message.success('刪除成功！')
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    async exportTmpl () {
      const res = await exportDataInspectionReptDemo()
      exportToxlsx(res, '检验报告Excel模板')
    },

    handleExcel (file) {
      const formData = new FormData()
      formData.append('file', file.raw)
      importDataInspectionRept(formData).then(res => {
        if (res.code == '200') {
          this.$message.success('文件导入成功！')
          this.initList()
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  components: {
    EditQualityTest,
    QualityTestDetail
  }
}
</script>
<style scoped lang="scss">
.search-form-box {
  ::v-deep .el-form-item {
    width: 100%;
    display: flex;
  }
  ::v-deep .el-form-item__content {
    flex: 1;
    .el-select, .el-date-editor, .el-cascader {
      width: 100%;
    }
  }
}
</style>
