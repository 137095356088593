import http from '@/utils/http'

// 获取编辑详情
export function dataInspectionReptDetail (p) {
  return http.get('/material/qualityManage/dataInspectionReptDetail', p)
}

// 获取 委托单位 和 质检单位
export function getRelaListByRelaType (p) {
  return http.get('/material/qualityManage/getRelaListByRelaType', p)
}

// 新增或编辑原材料检验报告
export function addOrUpdateInspectionRept (p) {
  return http.post('/material/qualityManage/addOrUpdateInspectionRept', p)
}

// 获取混凝土检验报告列表
export function getInspectionReptList (p) {
  return http.get('/material/qualityManage/getInspectionReptList', p)
}

// // 获取复检编号 checType 检验类型 1:初检 2:复检
// export function getQumaReptListByChecType (p) {
//   return http.get('/material/qualityManage/getQumaReptListByChecType', p)
// }

// 刪除混凝土检验报告
export function deleteDataInspectionRept (p) {
  return http.post('/material/qualityManage/deleteDataInspectionRept', p)
}

// 导出原材料检验报告
export function exportDataInspectionRept (p) {
  return http.postBlob('/material/qualityManage/exportDataInspectionRept', p)
}

// 导出模板
export function exportDataInspectionReptDemo (p) {
  return http.postBlob('/material/qualityManage/exportDataInspectionReptDemo', p)
}

// 导入
export function importDataInspectionRept (p) {
  return http.postForm('/material/qualityManage/importDataInspectionRept', p)
}
